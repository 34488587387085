/* PAGE -- DASHBOARD */
/* PAGE -- DASHBOARD */
/* PAGE -- DASHBOARD */

.dashboard-container {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  padding: 40px 0vw;
  min-height: calc(100vh - 128px - 1px - 80px);
  /* border-top: 1px solid rgb(255, 233, 233); */
  border-top: 1px solid #ffcade;
  background-color: rgba(255, 246, 246, 0.664);
}

.dashboard-container-referral {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  padding: 40px 0vw;
  min-height: calc(100vh - 1px - 80px);
  /* border-top: 1px solid rgb(255, 233, 233); */
  border-top: 1px solid #ffcade;
  background-color: rgba(255, 246, 246, 0.664);
}

.auth-container {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  padding: 40px 0px;
  min-height: calc(100vh - 72px - 1px - 80px);
  border-top: 1px solid rgb(255, 233, 233);
  background-color: rgba(255, 246, 246, 0.664);
}

.dashboard-container-max-medical {
  width: 1200px;
}

@media (max-width: 1300px) {
  .dashboard-container-max-medical {
    max-width: 1200px;
    padding: 0 4vw;
  }
}

.navbar-dashboard-container {
  margin-top: 32px;
}

@media (min-width: 700px) {
  .navbar-dashboard-container {
    display: none;
  }
}

.mobile-link-item-container {
  font-size: 16px;
  max-width: 100%;
  background-color: white;
  border-radius: 8px;
  border: 1px solid rgb(255, 233, 233);
  color: #555;
  padding: 24px 24px;
  display: flex;
  cursor: pointer;
  margin-bottom: 8px;
}

.mobile-link-item-container:hover {
  background-color: rgba(241, 241, 241, 0.5);
}

.mobile-link-item-container-logo {
  background-color: #ffe9e9;
  border-radius: 100%;
  height: 32px;
  width: 32px;
  padding: 10px;
  margin-right: 16px;
  color: #ff9b9b;
}

.mobile-link-item-container-title {
  font-size: 16px;
  font-weight: 700;
  color: #000;
  margin-bottom: 2px;
}
.mobile-link-item-container-sub {
  font-size: 13px;
  color: #999;
}

.dashboard-container-max {
  width: 560px;
  padding: 0 4vw;
}

@media (max-width: 600px) {
  .dashboard-container-max {
    width: 100%;

  }
}

.dashboard-container-max-dashboard {
  width: 560px;
  padding: 0 4vw;
}

@media (max-width: 600px) {
  .dashboard-container-max-dashboard {
    width: 100%;
  }
}

.dashboard-item-title {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 18px;
}

.dashboard-item-title-center {
  font-family: 'Bossa';
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 18px;
  text-align: center;
}

/* PAGE -- CONSULTATIONS */
/* PAGE -- CONSULTATIONS */
/* PAGE -- CONSULTATIONS */

.div-flex {
  display: flex;
}

.div-2-3 {
  padding-right: 18px;
  width: calc((100% - 36px) * 0.6);
}

.div-1-3 {
  padding-left: 18px;
  width: calc((100% - 36px) * 0.4);
}

.div-50l {
  padding-right: 18px;
  width: calc((100% - 36px) / 2);
}

.div-50r {
  padding-left: 18px;
  width: calc((100% - 36px) / 2);
}

.div-100w1 {
  width: 100%;
}

.consultations-item-container {
  font-size: 16px;
  /* font-weight: 500; */
  background-color: white;
  border-radius: 8px;
  border: 1px solid rgb(255, 233, 233);
  color: #555;
  padding: 28px 32px;
  margin-bottom: 16px;
}

.consultations-item-container-autochat {
  font-size: 14px;
  /* font-weight: 500; */
  background-color: white;
  border-radius: 8px;
  border: 1px solid rgb(255, 233, 233);
  color: #555;
  padding: 16px;
  margin-bottom: 16px;
}

.autochat-item {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  border-bottom: 1px dotted #eee;
  padding-bottom: 6px;
  
}

.autochat-item-text {
  background-color: #ffcade98;
  color: #000;
  padding: 2px 6px;
  border-radius: 8px;
  font-size: 13px;
  margin-right: 16px;
  display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	width: 100%;
  cursor: pointer;
}

.autochat-item-text-sleep {
  background-color: rgba(238, 238, 238, 0.5);
  color: #bbb;
  padding: 2px 6px;
  border-radius: 8px;
  font-size: 13px;
  margin-right: 16px;
  display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	width: 100%;
  cursor: pointer;
}


.consultations-info-text {
  margin-bottom: 12px;
}

.consultations-btn {
  cursor: pointer;
  border: none;
  font-family: 'Poppins', sans-serif;
  padding: 16px 22px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.02em;
  text-decoration: none;
  color: white;
  background-color: #000;
}

.autochat-submit-btn {
  cursor: pointer;
  border: none;
  font-family: 'Poppins', sans-serif;
  padding: 12px 16px;
  width: 100%;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.02em;
  text-decoration: none;
  color: white;
  background-color: #000;
  margin-top: 8px;
}

.autochat-submit-btn:disabled {
  cursor: pointer;
  border: none;
  font-family: 'Poppins', sans-serif;
  padding: 12px 16px;
  width: 100%;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.02em;
  text-decoration: none;
  color: white;
  background-color: #999;
  margin-top: 8px;
}

.autochat-submit-btn:hover {
  cursor: pointer;
  border: none;
  font-family: 'Poppins', sans-serif;
  padding: 12px 16px;
  width: 100%;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.02em;
  text-decoration: none;
  color: white;
  background-color: #333;
  margin-top: 8px;
}

.consultations-links {
  display: flex;
}

.consultations-btn-text {
  font-weight: 600;
  color: #9198e5;
  cursor: pointer;
  margin-right: 32px;
}

.consultations-btn-text:hover {
  color: #8389cf;
}

.consultations-btn-text-1 {
  font-weight: 600;
  color: #9198e5;
  cursor: pointer;
  margin-right: 32px;
  margin-top: 16px;
}

.consultations-btn-text-1:hover {
  color: #8389cf;
}

.consultations-type {
  font-weight: 700;
  margin-bottom: 0px;
}

.consultations-badge {
  border-radius: 6px;
  background-color: #ffcade;
  font-size: 12px;
  line-height: 12px;
  color: #eb6173;
  padding: 4px 8px;
  font-weight: 600;
  /* margin-bottom: 10px; */
  width: 100%;
  /* height: 12px; */
}

.consultations-date {
  font-size: 12px;
  color: #999;
  margin-top: 3px;
  margin-bottom: 12px;
}

/* PAGE -- PRESCRIPTIONS */
/* PAGE -- PRESCRIPTIONS */
/* PAGE -- PRESCRIPTIONS */

.prescriptions-item-container {
  font-size: 16px;
  background-color: white;
  border-radius: 8px;
  border: 1px solid rgb(255, 233, 233);
  color: #555;
  padding: 28px 32px 24px;
  margin-bottom: 16px;
}

/* PAGE -- SHIPPING */
/* PAGE -- SHIPPING */
/* PAGE -- SHIPPING */

.dashboard-container-max-500 {
  width: 560px;
}

@media (max-width: 1300px) {
  .dashboard-container-max-475 {
    max-width: 475px;
    padding: 0 4vw;
  }
}

@media (max-width: 600px) {
  .dashboard-container-max-500 {
    width: 100%;
  }
}

.w50 {
  width: calc((100%-16px) / 2);
}

.w100 {
  width: 100%;
}

.input-space {
  height: 24px;
}

.input-space-16 {
  height: 16px;
}

.input-space-12 {
  height: 12px;
}

.input-label {
  color: #555;
  font-weight: 500;
  font-size: 14px;
  /* line-height: 24px; */
  margin-bottom: 6px;
}

.input-text {
  font-family: 'Poppins', sans-serif;
  background: transparent;
  border: 2px solid #ddd;
  padding: 16px;
  width: 100%;
  border-radius: 5px;
  font-size: 16px;
  line-height: normal;
  font-weight: 600;
  height: 60px;
  color: #000;
  box-shadow: none;
  appearance: none;
  box-sizing: border-box;
  outline: 0;
}

.input-text-phone {
  font-family: 'Poppins', sans-serif;
  background: transparent;
  border: 2px solid #ddd;
  padding: 16px;
  width: 100%;
  border-radius: 5px;
  font-size: 16px;
  line-height: normal;
  font-weight: 600;
  height: 60px;
  letter-spacing: 0.02em;
  color: #000;
  box-shadow: none;
  appearance: none;
  box-sizing: border-box;
  outline: 0;
}

.input-text::placeholder {
  color: #bbb;
}

.input-text-phone::placeholder {
  color: #bbb;
}

.input-error {
  margin-top: 6px;
  font-size: 14px;
  color: red;
}

.input-space-flex {
  width: 16px;
}

.input-height-container {
  display: flex;
}

.input-textarea-small {
  background: #fffaf7;
  border: 2px solid #ddd;
  padding: 16px;
  width: 100%;
  border-radius: 5px;
  font-size: 16px;
  line-height: normal;
  font-weight: 600;
  height: 120px;
  color: #000;
  box-shadow: none;
  appearance: none;
  box-sizing: border-box;
  outline: 0;
  font-family: 'Poppins', sans-serif;
  resize: none;
  margin: 0;
  display: block;
}

.input-textarea-small-shipping {
  background: transparent;
  border: 2px solid #ddd;
  padding: 16px;
  width: 100%;
  border-radius: 5px;
  font-size: 16px;
  line-height: normal;
  font-weight: 600;
  height: 120px;
  color: #000;
  box-shadow: none;
  appearance: none;
  box-sizing: border-box;
  outline: 0;
  font-family: 'Poppins', sans-serif;
  resize: none;
  margin: 0;
  display: block;
}

.save-changes-btn {
  cursor: pointer;
  border: none;
  font-family: 'Bossa';
  padding: 21px 22px;
  border-radius: 8px;
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.02em;
  text-decoration: none;
  color: white;
  background-color: black;
  margin-top: 32px;
}
.save-changes-btn:hover {
  background-color: #222;
}

.login-btn {
  cursor: pointer;
  border: none;
  font-family: 'Poppins', sans-serif;
  padding: 21px 22px;
  border-radius: 8px;
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.02em;
  text-decoration: none;
  color: white;
  background-color: black;
  margin-top: 32px;
}

.already-account {
  font-size: 14px;
  margin-top: 16px;
  text-align: center;
}

.login-here {
  font-size: 14px;
  font-weight: 600;
  color: #9198e5;
  cursor: pointer;
}

.form-title {
  text-align: center;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 16px;
}

/* PROFILE */
/* PROFILE */
/* PROFILE */

.photo-frame {
  background: #fff;
  /* padding: 20px; */
  background-color: transparent;
  border: 2px dashed #dac3e4;
  border-radius: 6px;
  cursor: pointer;
  width: 471px;
  min-height: 322px;
  margin-right: auto;
  margin-left: auto;
  transition: background-color 0.2s linear;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.photo-frame2 {
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px;
  max-width: 100%;
  height: 300px;
  background-color: white;
  border: 1px solid #f5ece4;
}

@media (max-width: 600px) {
  .photo-frame2 {
    max-width: 100%;
  }
}

.photo-frame3 {
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
  max-width: 100%;
  height: 300px;
  /* background-color: rgb(230, 243, 255); */
  border: 1px solid #f5ece4;
}

@media (max-width: 600px) {
  .photo-frame3 {
    max-width: 100%;
  }
}

.photo-btn-text {
  font-weight: 600;
  color: #9198e5;
  cursor: pointer;
  text-align: center;
  margin-top: 12px;
}

.photo-btn-text:hover {
  color: #8389cf;
}

.modal-backdrop {
  background-color: #000000aa;
  height: 100vh;
  width: 100vw;
  position: fixed;
  /* position: absolute; */
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-container {
  background-color: white;
  padding: 32px;
  max-width: calc(475px + 4px);
  border-radius: 8px;
}

.modal-container2 {
  background-color: white;
  padding: 32px;
  width: 90%;
  max-width: calc(475px + 4px);
  border-radius: 8px;
  margin-right: 4vw;
  margin-left: 4vw;
}

.modal-title {
  font-size: 24px;
  line-height: 32px;
  color: #000;
  font-weight: 700;
  text-align: center;
  width: 100%;
  margin-top: 6px;
  margin-bottom: 24px;
}

.modal-text {
  color: #555;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  /* margin-bottom: 24px; */
}

.modal-btn {
  cursor: pointer;
  border: none;
  font-family: 'Poppins', sans-serif;
  padding: 21px 22px;
  border-radius: 8px;
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.02em;
  text-decoration: none;
  color: white;
  background-color: black;
}

.menu-btn {
  cursor: pointer;
  border: none;
  font-family: 'Poppins', sans-serif;
  padding: 18px 22px;
  width: 475px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.02em;
  text-decoration: none;
  color: white;
  background-color: #000;
  transition: all 0.2s ease-in-out;
}

.menu-btn2 {
  cursor: pointer;
  border: none;
  font-family: 'Poppins', sans-serif;
  padding: 18px 22px;
  width: 100%;
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.02em;
  text-decoration: none;
  color: white;
  background-color: #000;
  transition: all 0.2s ease-in-out;
}

.input-hidden {
  visibility: hidden;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 0px;
  width: 0px;
}

.test-upload-btn {
  background: #fff;
  /* padding: 20px; */
  background-color: transparent;
  border: 2px dashed #dac3e4;
  border-radius: 6px;
  cursor: pointer;
  width: 471px;
  min-height: 322px;
  margin-right: auto;
  margin-left: auto;
  transition: background-color 0.2s linear;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.test-upload-btn:hover {
  background-color: #f8f0fc;
}

.test-upload-btn2 {
  background: #fff;
  /* padding: 20px; */
  background-color: transparent;
  border: 2px dashed #dac3e4;
  border-radius: 6px;
  cursor: pointer;
  width: 100%;
  min-height: 322px;
  margin-right: auto;
  margin-left: auto;
  transition: background-color 0.2s linear;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.test-upload-btn2:hover {
  background-color: #f8f0fc;
}

.test-upload-btn-text {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: #979797;
  position: relative;
}

.image-upload {
  max-width: 440px;
  max-height: 300px;
  display: block;
}

@media (max-width: 600px) {
  .image-upload {
    width: 100%;
  }
}

.vid-black {
  background-color: black;
  opacity: 0.5;
  position: fixed;
  width: 440px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.vid-black2 {
  background-color: black;
  opacity: 0.5;
  width: 50px;
  height: 50px;
  position: absolute;
  /* z-index: 99; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.vid-wrapper {
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px;
  width: calc(100% - 24px - 2px);
  height: 300px;
  background-color: white;
  border: 1px solid #f5ece4;
}

.vid-wrapper video {
  width: 100%;
}

.nav-buttons-bottom {
  margin: 0px 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.nav-buttons-bottom50 {
  width: calc((100% - 16px) / 2);
}

.test-sub {
  font-size: 12px;
  max-width: 475px;
  text-align: left;
  color: #b7a697;
  margin-top: 16px;
}

.test-sub-container {
  max-width: 475px;
}

.test-switch {
  font-size: 12px;
  font-weight: 700;
  margin-top: 8px;
  text-align: left;
  color: #d14010;
  cursor: pointer;
  text-decoration: underline;
  transition: all 0.2s ease-in-out;
  margin-bottom: 16px;
}

.test-switch:hover {
  opacity: 0.75;
}

.take-photo-btn {
  cursor: pointer;
  border: none;
  font-family: 'Poppins', sans-serif;
  padding: 21px 22px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.02em;
  text-decoration: none;
  color: white;
  background-color: #000;
  width: 100%;
}

.image-upload {
  max-width: 440px;
  max-height: 300px;
  display: block;
  /* z-index: 100; */
}

.take-photo-btn-space {
  width: 16px;
}

/* LOADER */
/* LOADER */
/* LOADER */
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* BILLING */

.billing-item-container {
  font-size: 16px;
  /* font-weight: 500; */
  background-color: white;
  border-radius: 8px;
  border: 1px solid rgb(255, 233, 233);
  color: #555;
  padding: 32px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.billing-btn-text {
  font-weight: 600;
  color: #9198e5;
  cursor: pointer;
}

.billing-btn-text:hover {
  color: #8389cf;
}

.billing-info-text {
  margin-bottom: 12px;
}

.cc-container {
  background-color: rgb(230, 243, 255);
  border-radius: 4px;
  height: 48px;
  padding: 6px 0px;
  width: 100%;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  margin-bottom: 16px;
}

.cc-network {
  margin-left: 16px;
  margin-right: 16px;
  background-color: #9198e5;
  color: white;
  font-size: 14px;
  font-weight: 600;
  border-radius: 4px;
  padding: 4px 8px;
}
.cc-number {
  letter-spacing: 0.06em;
}

.card-btn-container {
  display: flex;
  width: 100%;
}

.card-title {
  font-size: 18px;
  font-weight: 600;
}

.card-btn-cancel {
  margin-right: 9px;
  width: calc((100% - 18px) / 2);
  cursor: pointer;
  border: none;
  font-family: 'Poppins', sans-serif;
  padding: 20px 22px;
  border-radius: 8px;
  border: 2px solid black;
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.02em;
  text-decoration: none;
  color: black;
  background-color: white;
  margin-top: 32px;
}

.card-btn-add {
  margin-left: 9px;
  width: calc((100% - 18px) / 2);
  cursor: pointer;
  border: none;
  font-family: 'Poppins', sans-serif;
  padding: 20px 22px;
  border-radius: 8px;
  border: 2px solid black;
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.02em;
  text-decoration: none;
  color: white;
  background-color: black;
  margin-top: 32px;
}

.input-submit {
  display: none;
}

/* PAGE -- REFER */
/* PAGE -- REFER */
/* PAGE -- REFER */

.refer-code {
  background-color: rgb(230, 243, 255);
  padding: 26px 12px;
  text-align: center;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 700;
}

.refer-btn-text {
  font-weight: 600;
  color: #9198e5;
  cursor: pointer;
  /* margin-right: 32px; */
  margin-top: 32px;
  margin-bottom: 48px;
  text-align: center;
}

.refer-btn-text:hover {
  color: #8389cf;
}

.refer-title {
  /* font-family: 'Bossa'; */
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 16px;
}

.refer-text {
  margin-bottom: 32px;
}

/* .refer-hiw-list li {
  display: block;
}
.refer-hiw-list li:before {
  content: counter(item) '. ';
  counter-increment: item;
  width: 1em;
  display: inline-block;
} */

.refer-hiw-list {
  padding-left: 20px;
  margin: 0;
  /* list-style-position: inside; */
}

.refer-li {
  margin-bottom: 12px;
}

.div-flex-refer {
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

.div-50 {
  width: calc((100%) / 2);
}

.div-50-max {
  width: 560px;
}

.div-next-max {
  width: 480px;
}

.div-next-max2 {
  width: 420px;
}



@media (max-width: 600px) {
  .div-50-max {
    width: 100%;
  }
  .div-next-max {
    width: 100%;
  }
}

.refer-item-container {
  font-size: 16px;
  /* font-weight: 500; */
  background-color: white;
  border-radius: 8px;
  border: 1px solid rgb(255, 233, 233);
  color: #555;
  padding: 32px;
}

.profile-item-container {
  font-size: 16px;
  /* font-weight: 500; */
  background-color: white;
  border-radius: 8px;
  border: 1px solid rgb(255, 233, 233);
  color: #555;
  padding: 32px;
}

.profile-item-container-register {
  font-size: 16px;
  /* font-weight: 500; */
  background-color: white;
  border-radius: 8px;
  border: 1px solid rgb(255, 233, 233);
  color: #555;
  padding: 32px;
}



@media (max-width: 600px) {
  .profile-item-container-register {
    padding: 4vw;
    margin: 4vw;
  }
}

/* DASHBOARD */
/* DASHBOARD */
/* DASHBOARD */

.take-survey-btn {
  cursor: pointer;
  border: none;
  font-family: 'Poppins', sans-serif;
  padding: 21px 22px;
  border-radius: 8px;
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.02em;
  text-decoration: none;
  color: white;
  background-color: black;
  margin-top: 24px;
}

.dashboard-item-container {
  font-size: 16px;
  /* font-weight: 500; */
  background-color: white;
  border-radius: 8px;
  border: 1px solid rgb(255, 233, 233);
  color: #555;
  padding: 32px;
}

.next-dashboard-btn {
  cursor: pointer;
  border: none;
  font-family: 'Poppins', sans-serif;
  padding: 21px 22px;
  border-radius: 8px;
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.02em;
  text-decoration: none;
  color: white;
  background-color: black;
  margin-top: 24px;
}

.dashboard-item-container-next {
  font-size: 16px;
  /* font-weight: 500; */
  background-color: white;
  border-radius: 8px;
  border: 1px solid rgb(255, 233, 233);
  color: #555;
  padding: 32px 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.stripe-field-buttons {
  display: flex;
  margin-top: 0px;
}

.inputField-paysafe {
  border: 1px solid #E5E9EC;
  border-radius: 5px 5px 0px 0px;
  height: 40px;
  padding-left: 10px;
  width: 100%;
}

.inputField-form {
  display: flex;
  flex-wrap: wrap;
}

.page-sub-link-checkout {
  font-size: 14px;
  font-weight: bold;
  text-decoration: underline;
  color: #555;
  cursor: pointer;
}

.inputField-paysafe2 {
  border: 1px solid #E5E9EC;
  border-width: 0px 0px 1px 1px;
  border-radius: 0px 0px 0px 5px;
  height: 40px;
  padding-left: 10px;
  width: calc(50% - 11px);
  margin-bottom: 16px;
}
.inputField-paysafe3 {
  border: 1px solid #E5E9EC;
  border-width: 0px 1px 1px 0px;
  border-radius: 0px 0px 5px 0px;
  height: 40px;
  padding-left: 10px;
  width: calc(50% - 11px);
  margin-bottom: 16px;
}

.back-gradient-test {
  background-color: #F3F6F9;
    background-image: url('/landing/gradient.svg');
    background-size: cover;
    background-repeat: no-repeat;
    height: 700px;
}

.inputField-paysafe-container {
  display: flex;
}

.stripe-field-button-cancel {
  cursor: pointer;
  border: none;
  font-family: 'Bossa';
  padding: 16px 22px;
  width: calc((100% - 16px)/2);
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.02em;
  text-decoration: none;
  color: #777;
  background-color: #fffaf7;
  border: 1px solid #ddd;
}

.stripe-field-button-add {
  cursor: pointer;
  border: none;
  font-family: 'Bossa';
  padding: 16px 22px;
  width: calc((100% - 16px)/2);
  margin-left: auto;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.02em;
  text-decoration: none;
  color: white;
  background-color: #000;
  
}

.stripe-field-button-add-full {
  cursor: pointer;
  border: none;
  font-family: 'Bossa';
  padding: 16px 22px;
  width: 100%;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.02em;
  text-decoration: none;
  color: white;
  background-color: #000;
  
}

.image-next {
  margin-bottom: 12px;
}

.dash-next-list {
  display: flex;
  flex-direction: column;
  margin: 0px;
  padding: 0px;
}

.dash-next-list-li {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  padding: 10px 0px;
}

.dash-next-list-li-last {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  padding: 10px 0px;
}

.dash-next-list-li::before {
  content: '';
  display: block;
  width: 3px;
  background-color: #ffcade;
  height: calc(100% - 30px);
  position: absolute;
  left: 13.5px;
  top: 40px;
}

.dash-dot-complete {
  align-self: flex-start;
  min-width: 24px;
  min-height: 24px;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  border: 3px solid #ffcade;
  background-color: #ffcade;
  color: rgb(255, 255, 255);
  font-weight: 700;
  position: relative;
  color: #eb6173;
}

.dash-next-list-li-inprogress {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  padding: 10px 0px;
}

.dash-dot-inprogress {
  align-self: flex-start;
  min-width: 24px;
  min-height: 24px;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  border: 3px solid #ffcade;
  background-color: white;
  color: rgb(255, 255, 255);
  font-weight: 700;
  position: relative;
  color: #800d2e;
}

.dash-next-list-li-incomplete {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  padding: 10px 0px;
}

.dash-dot-incomplete {
  align-self: flex-start;
  min-width: 24px;
  min-height: 24px;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  border: 3px solid rgb(226, 226, 226);
  background-color: white;
  color: rgb(255, 255, 255);
  font-weight: 700;
  position: relative;
  color: #800d2e;
}
.dash-dot-title {
  /* font-family: 'Athletics'; */
  margin-left: 16px;
  font-size: 16px;
  font-weight: 600;
  line-height: 30px;
  color: #000;
}

.dash-dot-title-complete {
  /* font-family: 'Athletics'; */
  margin-left: 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  color: #bbb;
}

.dash-dot-sub {
  /* font-family: 'Athletics'; */
  margin: 2px 0px 0px 16px;
  font-size: 13px;
  color: #666;
}

.dash-dot-contain {
  flex-direction: column;
  display: flex;
}

.input-button-space-survey {
  height: 16px;
}

.button-choice-survey {
  cursor: pointer;
  background-color: #fffaf7;
  border: 2px solid #ddd;
  font-family: 'Poppins', sans-serif;
  padding: 0px 0px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  width: 100%;
  height: 64px;
  letter-spacing: 0.02em;
  text-decoration: none;
  color: black;
  /* margin-right: 32px; */
}

.button-choice-survey:hover {
  background-color: #dfe2fc;
  border: 2px solid #dfe2fc;
  color: black;
}
.button-choice-sub-survey {
  font-weight: 400;
  font-size: 14px;
}

.button-choice-active-survey {
  cursor: pointer;
  background-color: #9198e5;
  border: 2px solid #9198e5;
  font-family: 'Poppins', sans-serif;
  padding: 0px 0px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  width: 100%;
  height: 64px;
  letter-spacing: 0.02em;
  text-decoration: none;
  color: white;
  margin-right: 32px;
}

.dashboard-skeleton-line {
  height: 28px;
  background-color: rgb(231, 231, 231);
}

.dashboard-skeleton-line-below {
  height: 12px;
  background-color: transparent;
}

.prescriptions-item {
  background-color: #eee;
  padding: 16px;
  border-radius: 6px;
  min-height: 100px;
}

/* CHECKOUT */
/* CHECKOUT */
/* CHECKOUT */

.img-checkout-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-checkout1 {
  max-height: 200px;
}

.img-checkout2 {
  /* max-height: 200px; */
  border-radius: 8px;
}

.page-sub-spacer {
  margin-bottom: 32px;
}

.page-bottom {
  font-size: 10px;
  color: #777;
  margin-top: 16px;
}

.choices-container {
  margin-top: 32px;
  margin-bottom: 16px;
}

.choices-option-name {
  text-align: left;
  margin-bottom: 8px;
}
.choices-option-pricing {
  text-align: left;
  font-size: 14px;
  color: #777;
  font-weight: 500;
}
.choices-option-billing {
  text-align: left;
  font-size: 14px;
  color: #777;
  font-weight: 500;
}

.button-choice {
  cursor: pointer;
  background-color: #fffaf7;
  border: 2px solid #ddd;
  font-family: 'Poppins', sans-serif;
  padding: 12px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  width: 100%;
  letter-spacing: 0.02em;
  text-decoration: none;
  color: black;
  /* margin-right: 32px; */
}

.button-choice:hover {
  background-color: #dfe2fc;
  border: 2px solid #dfe2fc;
  color: black;
}

.button-choice-active {
  cursor: pointer;
  background-color: #9198e5;
  border: 2px solid #9198e5;
  font-family: 'Poppins', sans-serif;
  padding: 12px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  width: 100%;
  letter-spacing: 0.02em;
  text-decoration: none;
  color: white;
  /* margin-right: 32px; */
}

.button-choice-active div {
  color: white;
}

.add-cart-img {
  width: 100%;
  display: block;
}

.add-cart-img3 {
  width: 80px;
  margin-right: 12px;
  display: block;
}



.add-cart-img2 {
  width: 100%;
  display: block;
  border-radius: 6px;
}



.cart-item-btn-add {
  cursor: pointer;
  border: none;
  font-family: 'Bossa';
  padding: 12px 12px;
  border-radius: 4px;
  width: 100%;
  border: 1px solid black;
  /* margin-top: 24px; */
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.02em;
  text-decoration: none;
  color: white;
  background-color: #000;
}

.cart-item-btn-remove {
  cursor: pointer;
  border: none;
  font-family: 'Bossa';
  padding: 12px 12px;
  border-radius: 4px;
  border: 1px solid black;
  width: 100%;
  /* margin-top: 24px; */
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.02em;
  text-decoration: none;
  color: black;
  background-color: transparent;
}

.space-16 {
  margin-top: 24px;
}

.cart-item-btn-add2 {
  cursor: pointer;
  border: none;
  font-family: 'Bossa';
  padding: 12px 12px;
  border-radius: 4px;
  width: 100%;
  border: 1px solid #E1FF64;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.02em;
  text-decoration: none;
  color: #2871F6;
  background-color: #E1FF64;
  margin-top: 16px;
}

.cart-item-btn-remove2 {
  cursor: pointer;
  border: none;
  font-family: 'Bossa';
  padding: 12px 12px;
  border-radius: 4px;
  border: 1px solid #2871F6;
  width: 100%;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.02em;
  text-decoration: none;
  color: #2871F6;
  background-color: transparent;
  margin-top: 16px;
}

.add-cart-img-container {
  background-color: transparent;
  /* border: #000 1px solid; */
  border-radius: 5px;
  /* margin-bottom: 6px; */
}

.add-cart-item-container {
  width: 200px;
  margin-bottom: 48px;
  margin-left: 12px;
  margin-right: 12px;
}

.add-cart-item-container2 {
  width: 140px;
  margin-bottom: 48px;
  margin-left: 12px;
  margin-right: 12px;
}

.add-cart-item-container3 {
  width: 200px;
  margin-bottom: 48px;
  margin-left: 12px;
  margin-right: 12px;
  border: 1px #FFCADE solid;
  border-radius: 6px;
  padding: 16px;
}

.add-cart-item-container4 {
  width: 100%;
  box-sizing: border-box;
  background-color: white;
  /* margin-bottom: 24px; */
  /* margin-left: 12px;
  margin-right: 12px; */
  border: 1px #FFCADE solid;
  border-radius: 6px;
  padding: 16px;
}

.add-cart-item-tag {
  background-color: #9198e550;
  font-size: 12px;
  margin-left: 16px;
  border-radius: 6px 6px 0px 0px;
  padding: 3px 8px;
  margin-right: auto;
  position: relative;
  display: flex;
}

.add-cart-item-info {
  width: 16px;
  cursor: pointer;
  color: #0000007d;
  margin-left: 6px; 
}

.infobadge {
  display: flex;
}

.pop-hidden {
  display: none;
}

.pop-background {
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  border-radius: 6px;
  padding: 8px;
  margin-left: 12px;
  font-size: 12px;
}

.qr-code-container {
  display: flex;
  justify-content: center;
}

.qr-code svg {
  display: block;
}

.referral-code {
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  font-size: 16px;
  font-weight: 900;
  color: #2871f6;
  letter-spacing: 0.2em;
  margin-top: 16px;
  margin-bottom: 16px;
}

.referral-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 16px;
  text-align: center;
}

.referral-rules-container {
  font-size: 14px;
  color: #555;
  text-align: center;
  margin-top: 24px;
  margin-bottom: 24px;
}

.referral-rules-margin {
  margin-bottom: 6px;
}

.copy-referral-code {
  font-size: 14px;
  font-family: 'Bossa';
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  padding: 14px 24px;
  border-radius: 50px;
  font-size: 16px;
  letter-spacing: 0.02em;
  text-decoration: none;
  color: #e1ff64;
  background-color: #2871f6;
  border: none;
  width: 100%;
  max-width: 420px;
  cursor: pointer;
}

.copy-referral-code:hover {
  background-color: #222;
}

.pop-background2 {
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  border-radius: 6px;
  padding: 8px;
  margin-bottom: 12px;
  width: 400px;
  font-size: 12px;
}

.add-cart-item-container-lr {
display: flex;
}


@media (max-width: 500px) {
  .add-cart-item-container {
    width: 150px;
  }
}

@media (max-width: 400px) {
  .add-cart-item-container {
    width: 140px;
    margin-left: 6px;
    margin-right: 6px;
  }
}


.add-cart-item-description {
  font-size: 13px;
}

.patient-consults-link {
  text-decoration: underline;
  cursor: pointer;
  opacity: 0.6;
}
.patient-consults-link:hover{
  opacity: 0.8;
}

.add-cart-item-description2 {
  font-size: 14px;
  font-weight: bold;
  margin-top: 12px;
  margin-bottom: 12px;
}

.add-cart-item-description3 {
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 3px;
  margin-top: 3px;
  margin-right: 8px;
}

.add-cart-item-description3-price {
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 3px;
  margin-top: 3px;
  margin-left: auto;
}

.add-cart-item-description-sub {
  font-size: 11px;
  font-weight: 400;
  color: #777;
  margin-right: 8px;
}

.add-cart-item-quantity {
  font-size: 10px;
  color: #777;
  margin-bottom: 6px;
}

.price-container {
  display: flex;
  color: #000;
  font-weight: 600;
  margin-top: 12px;
  margin-bottom: 12px;
}

.price-container {
  display: flex;
  align-items: center;
  color: #000;
  font-weight: 600;
  margin-top: 12px;
  margin-bottom: 12px;
}

.price-container-text1 {
  color: #000;
  font-weight: 700;
  letter-spacing: 0.01em;
}

.price-container-text-light {
  color: #000;
  font-weight: 400;
  line-height: 24px;
}

.price-container-text-action {
  color: #000;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
  text-decoration: underline;
  color: rgb(54, 54, 209, 0.7);
  margin-left: 6px;
}

.price-container-emoji {
  margin-right: 6px;
}

.price-container-text1-sec {
  color: #000;
  font-weight: 300;
  letter-spacing: 0.01em;
}

.price-container-text2 {
  color: #000;
  font-weight: 600;
  margin-bottom: 8px;
}

.disclaimer-container {
  color: #000;
  font-weight: 300;
  font-size: 13px;
  background-color: rgb(255, 231, 231, 0.8);
  border-radius: 5px;
  padding: 16px
}

.checkout-med-img2 {
  width: 100%;
  max-width: 320px;
  border-radius: 5px;
  margin-top: 12px;
  margin-bottom: 24px;
}

.checkout-med-img {
  width: 80px;
  height: 80px;
  border-radius: 5px;
  margin-right: 16px;
}
.checkout-care-img {
  width: 80px;
  height: 80px;
  border-radius: 5px;
  margin-right: 16px;
}
.price-container-margin {
  width: 100%;
}

.when-charged {
  margin-left: auto;
  margin-top: 6px;
  cursor: pointer;
  text-decoration: underline;
  color: rgb(54, 54, 209);
}

.when-charged:hover {
  color: black;
}

.price-container2  {
  display: flex;
  color: #777;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 10px;
}
.price-container3  {
  display: flex;
  color: #777;
  font-weight: 400;
  margin-top: 8px;
  margin-bottom: 16px;
}

.price-container-sub {
  font-size: 12px;
  margin-top: 3px;
  margin-bottom: 3px;
  color: #777;
}

.price-container-sub {
  font-size: 12px;
  margin-bottom: 3px;
  font-weight: 400;
  color: #777;
}

.price-container-final {
  color: #000;
  font-weight: 700;
  font-size: 18px;
  display: flex;
  margin-top: 8px;
  margin-bottom: 0px;
}

.price-line-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}



.price-container-after {
  color: #777;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  margin-top: 8px;
  margin-bottom: 0px;
}

.receipt-container {
  font-size: 14px;
  margin-top: 48px;
  margin-bottom: 32px;
}

.receipt-container2 {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  
}

.receipt-container2-row {
  display: flex;
}

.receipt-container2-col {
  width: 100%;
  display: flex;
  flex-direction: column;
}

@media (max-width: 350px) {
  .receipt-container2-row {
    flex-direction: column;
  }
}


.price-container-price {
  display: flex;
  font-weight: 700;
  margin-left: auto;
  max-width: 85px;
  text-align: right;
}

.price-container-price1 {
  display: flex;
  font-weight: 700;
  margin-left: 12px
  /* margin-left: auto; */
  /* min-width: 100px; */
  /* text-align: right; */
}

.price-container-price-shipping {
  display: flex;
  font-weight: 700;
  margin-left: 12px;
  margin-left: auto;
  /* min-width: 100px; */
  /* text-align: right; */
}

.price-container-price-today {
  font-weight: 700;
  margin-left: auto;
  min-width: 85px;
  text-align: right;
}

.price-container-price-today2 {
  font-weight: 700;
  width: 60px;
  text-align: right;
}

.price-container-price-today-shipping {
  font-weight: 400;
  text-align: right;
}

.price-container-price-today3 {
  font-weight: 700;
  width: 60px;
  text-align: right;
  margin-left: auto;
}

.price-container-price-today3-sub {
  font-size: 12px;
  margin-bottom: 3px;
  font-weight: 400;
  color: #777;
  text-align: right;
  
}



.start-page-container {
  display: flex;
}

.price-container-price2 {
  font-weight: 700;
  margin-left: auto;
  min-width: 70px;
  text-align: right;
}

.price-container-price3 {
  font-weight: 700;
  margin-left: auto;
  min-width: 70px;
  text-align: right;
  color: rgb(22, 165, 22);
}

.square-page {
  margin: 40px 32px;
}

.square-banner {
  background: #e5f6ff;
  border: solid 1px #ccedff;
  margin: 40px 0px;
  padding: 16px;
  border-radius: 4px;
  color: #373f4a; 
}

.card-form-visible {
  display: block;
}

.card-form-invisible {
  display: none;
}

.square-wrap {
  pointer-events: none;
    position: relative;
    top: 12px;
    left: -26px;
    margin-left: 120px;
}

.square-attention {
  background: #006be6;
  border-radius: 50%;
  height: 16px;
  left: -8px;
  position: absolute;
  top: -8px;
  width: 16px;
}

.square-attention-pulse {
    background: #006be6;
    border-radius: 50%;
    height: 16px;
    left: -8px;
    position: absolute;
    top: -8px;
    width: 16px;
    animation-duration: 1.5s;
    animation-timing-function: ease-out;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-fill-mode: none;
    animation-play-state: running;
    animation-name: pulsate;
    opacity: 0;
}

@keyframes pulsate {
  0%, 100% {
    transform: scale(.1,.1);
    opacity: 0;
  }

  33% {
    opacity: .3;
  }
  66% {
    transform: scale(4,4);
    opacity: 0;
  }
}


.price-container-price-item {
  font-weight: 700;
  margin-left: auto;
}

.price-container-question {
  width: 20px; 
  color: #8389cf;
  cursor: pointer;
  margin-right: 6px;
}

.price-container-question-shipping {
  width: 20px; 
  height: 20px;
  color: #8389cf;
  cursor: pointer;
  margin-right: 6px;
  display: block;
}

.price-container-price-item-sec {
  font-weight: 400;
  margin-left: auto;
}

.price-container-price-green {
  font-weight: 400;
  margin-left: auto;
  color: rgb(22, 165, 22);
}

.price-container-price-red {
  font-weight: 700;
  margin-left: auto;
  color: red;
}

.price-line-sep {
  border-bottom: 1px dotted #bbb;
  margin-top: 16px;
  margin-bottom: 16px;
}
.price-line-sep2 {
  border-bottom: 1px dotted #bbb;
  margin-top: 2px;
  margin-bottom: 2px;
}

.price-line-sep3 {
  border-bottom: 1px dashed #ccc;
  margin-top: 16px;
  margin-bottom: 16px;
}

.checkout-item-title {
  font-size: 20px;
  margin-bottom: 12px;
}

.checkout-item {
  border-radius: 4px;
  border: 1px solid #ddd;
  padding: 16px;
  font-weight: 400;
  font-size: 12px;
  color: #777;
  display: flex;
}

.checkout-item {
  display: flex;
} 

.checkout-item-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkout-item-mastercard {
  width: 28px;
  display: block;
}

.checkout-item-mastercard-container {
  padding: 6px 6px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-right: 16px;
  display: flex;
}

.checkout-item-edit {
  margin-left: auto;
  cursor: pointer;
  text-decoration: underline;
  color: rgb(54, 54, 209);
}

.checkout-item-edit:hover {
  color: black;
}

.page-success-btn {
  cursor: pointer;
  border: none;
  font-family: 'Bossa';
  padding: 16px 22px;
  border-radius: 8px;
  margin-top: 24px;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.02em;
  text-decoration: none;
  color: white;
  background-color: #000;
}

.page-success-btn-nav {
  cursor: pointer;
  border: none;
  font-family: 'Bossa';
  padding: 16px 22px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.02em;
  text-decoration: none;
  color: white;
  background-color: #000;
}

.page-container2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.page-container3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* width: 100%; */
  max-width: 420px;
}

.stripe-field {
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  padding: 16px;
  border-radius: 5px;
  border: 2px solid #ddd;
  font-weight: 600;
  color: #000;
}

.stripe-field-group {
  display: flex;
  width: 100%;
}

.stripe-field-group-3 {
  width: calc((100% - 12px)/2);
}
.stripe-field-group-3-space {
  margin-right: 12px;
}

.stripe-field-group-3-div {
  margin-bottom: 16px;
}

.stripe-field-1-3 {
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  padding: 16px;
  border-radius: 5px;
  border: 2px solid #ddd;
  font-weight: 600;
  color: #000;
  
}

.stripe-field-container {
  width: 100%;
}

.billing-item-container2 {
  font-size: 16px;
  /* font-weight: 500; */
  background-color: white;
  border-radius: 8px;
  border: 1px solid rgb(255, 233, 233);
  color: #555;
  padding: 32px;
  margin-bottom: 16px;
  /* display: flex;
  flex-direction: column;
  align-items: center; */
}

.addcard-btn {
  cursor: pointer;
  border: none;
  font-family: 'Poppins', sans-serif;
  padding: 14px 18px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.02em;
  text-decoration: none;
  color: white;
  background-color: #000;
}

.addcard-btn:hover {
  background-color: #222;
}

.prescription-title {
  margin-bottom: 6px;
  font-weight: 600;
  font-size: 18px;
}

.prescription-sub {
  font-size: 12px;
  color: #999;
  margin-bottom: 16px;
}

.prescription-info {
  margin-top: 16px;
  font-size: 14px;
  font-weight: 600;
  color: #555;
}

.prescription-info-line {
  margin-bottom: 6px;
}

.prescription-checkout-btn {
  cursor: pointer;
  border: none;
  font-family: 'Bossa';
  padding: 14px 18px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.02em;
  text-decoration: none;
  color: #fff;
  background-color: #000;
  margin-top: 16px;
}
.prescription-checkout-btn:hover {
  background-color: #222;
}

/* CHAT */
/* CHAT */
/* CHAT */

.chat-container-container {
  font-size: 16px;
  background-color: white;
  border-radius: 8px;
  border: 1px solid rgb(255, 233, 233);
  color: #555;
  padding: 24px 24px 12px 24px;
  max-height: 450px;
  overflow: scroll;
  overflow: overlay;
}

.chat-container-container::-webkit-scrollbar {
  width: 9px;
  height: 25px;
  padding-right: 2px;
}

.chat-container-container::-webkit-scrollbar-thumb {
  background: rgb(206, 206, 206);
  border-radius: 5px;
}

.chat-container-container::-webkit-scrollbar-track {
  background: rgba(255, 255, 255);
}

/* .chat-container-container::-webkit-scrollbar {
  display: none;
} */

.chat-container-container2 {
  font-size: 16px;
  background-color: white;
  border-radius: 8px;
  border: 1px solid rgb(255, 233, 233);
  color: #555;
  padding: 24px 24px 12px 24px;
  margin-bottom: 16px;
  max-height: 600px;
  overflow: scroll;
  overflow: overlay;
}

.chat-container-container2::-webkit-scrollbar {
  width: 9px;
  height: 25px;
  padding-right: 2px;
}

.chat-container-container2::-webkit-scrollbar-thumb {
  background: rgb(206, 206, 206);
  border-radius: 5px;
}

.chat-container-container2::-webkit-scrollbar-track {
  background: rgba(255, 255, 255);
}

.chat-container {
  display: flex;
  flex-direction: column;
}

.chat-msg-container {
  padding: 8px 16px;
  font-size: 13px;
  border-radius: 4px;
  background-color: #ffe9e9;
  width: fit-content;
  align-self: flex-start;
}

.chat-container-none {
  /* padding: 8px 16px; */
  font-size: 13px;
  color: #555;
}

.chat-msg-container-other {
  padding: 8px 16px;
  font-size: 13px;
  border-radius: 4px;
  background-color: #f0f0f0;
  width: fit-content;
  align-self: flex-end;
}

.chat-timestamp-container {
  font-size: 10px;
  color: #aaa;
  width: max-content;
  align-self: flex-start;
  margin-top: 4px;
  margin-bottom: 12px;
}

.chat-timestamp-container-other {
  font-size: 10px;
  color: #aaa;
  width: max-content;
  align-self: flex-end;
  margin-top: 4px;
  margin-bottom: 12px;
}

.chat-input-container {
  margin-top: 32px;
  font-size: 16px;
  background-color: white;
  border-radius: 8px;
  border: 1px solid rgb(255, 233, 233);
  color: #555;
  padding: 16px 24px;
  display: flex;
}

.chat-input-container2 {
  margin-top: 16px;
  font-size: 16px;
  background-color: white;
  /* border-radius: 8px; */
  /* border: 1px solid rgb(255, 233, 233); */
  border-top: 1px solid rgb(187, 187, 187);
  color: #555;
  padding: 16px 0 8px 0;
  display: flex;
}

.chat-input-container3 {
  margin-top: 16px;
  font-size: 16px;
  background-color: white;
  /* border-radius: 8px; */
  /* border: 1px solid rgb(255, 233, 233); */
  border-top: 1px solid rgb(187, 187, 187);
  color: #555;
  padding: 16px 0 8px 0;
  height: 100px;
  display: flex;
}

.chat-input-field {
  font-size: 14px;
  border: none;
  width: 80%;
  outline: none;
  font-family: 'Poppins', sans-serif;
}

.chat-input-field1 {
  font-size: 13px;
  border: none;
  width: 80%;
  outline: none;
  resize: none;
  height: 100px;
  font-family: 'Poppins', sans-serif;
}

.chat-input-btn-active {
  font-size: 14px;
  background-color: rgb(62, 82, 148);
  border: none;
  color: #555;
  padding: 12px;
  border-radius: 4px;
  font-family: 'Poppins', sans-serif;
  text-decoration: none;
  color: white;
  cursor: pointer;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
}

.chat-input-btn-active:hover {
  background-color: rgba(62, 82, 148, 0.8);
}

.chat-input-btn-active2 {
  font-size: 14px;
  background-color: rgb(62, 82, 148);
  border: none;
  color: #555;
  padding: 12px;
  border-radius: 4px;
  font-family: 'Poppins', sans-serif;
  text-decoration: none;
  color: white;
  cursor: pointer;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  height: 54px;
}

.chat-input-btn-active2:hover {
  background-color: rgba(62, 82, 148, 0.8);
}

.chat-input-btn {
  font-size: 14px;
  background-color: #fff;
  border: none;
  padding: 12px;
  border-radius: 4px;
  font-family: 'Poppins', sans-serif;
  text-decoration: none;
  color: #aaa;
  cursor: pointer;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
}

.chat-input-btn2 {
  font-size: 14px;
  background-color: #fff;
  border: none;
  padding: 12px;
  border-radius: 4px;
  font-family: 'Poppins', sans-serif;
  text-decoration: none;
  color: #aaa;
  /* border: solid 2px #eee; */
  background: #eee;
  cursor: pointer;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  height: 54px;
}

.chat-input-btn svg {
  height: 24px;
  width: 24px;
  margin-left: 2px;
  transform: rotate(90deg);
}

.chat-input-btn2 svg {
  height: 24px;
  width: 24px;
  margin-left: 2px;
  transform: rotate(90deg);
}

.chat-input-btn-active svg {
  height: 24px;
  width: 24px;
  margin-left: 2px;
  transform: rotate(90deg);
}

.chat-input-btn-active2 svg {
  height: 24px;
  width: 24px;
  margin-left: 2px;
  transform: rotate(90deg);
}


.stripe-field-title {
  font-size: 12px;
  font-weight: 500;
  color: #888;
  margin-bottom: 3px;
}

